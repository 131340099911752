header {
  padding: 8px 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);;
  nav {
    display: flex;
    justify-content: space-between;

    .navbar {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .nav-item {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .userpic {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 63px;
        height: 100%;
        &.student {
          background-image: url("../images/main/student.png");
        }
        &.parent {
          background-image: url("../images/main/parent.png");
        }
        &.ex-student {
          background-image: url("../images/main/ex-student.png");
        }
        &.teacher {
          background-image: url("../images/main/teacher.png");
        }
        &.guest {
          background-image: url("../images/main/guest.png");
        }
      }
      .nav-link {
        color: #000;

        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
          color: #2D7079;
          text-decoration: underline;
        }
      }

      .nav-search {
        position: relative;
        input {
          border-radius: 20px;
          border: 1px solid #D9D9D9;
          background: #FFF;
          color: #8C8C8C;

          font-family: "Rubik";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 10px 35px 10px 16px;
          outline: none;
          &::placeholder {
            color: #8C8C8C;
          }
          &::-webkit-search-decoration,
          &::-webkit-search-cancel-button,
          &::-webkit-search-results-button,
          &::-webkit-search-results-decoration {
            display: none;
          }
          &:hover {
            border: 1px solid #BFBEBE;
            &::placeholder {
              color: #717171;
            }
          }
          &:focus {
            width: 600px;
          }
        }
        &:after {
          content: '';
          position: absolute;
          right: 20px;
          top:0;
          display: block;
          width: 15px;
          height: 37px;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3e%3ccircle cx='7.96039' cy='7.435' r='6' stroke='black' stroke-width='2'/%3e%3cpath d='M15.7533 16.1421C16.1438 16.5326 16.777 16.5326 17.1675 16.1421C17.558 15.7516 17.558 15.1184 17.1675 14.7279L15.7533 16.1421ZM11.7533 12.1421L15.7533 16.1421L17.1675 14.7279L13.1675 10.7279L11.7533 12.1421Z' fill='black'/%3e%3c/svg%3e");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
        }
      }
      .nav-search:has(> input:focus) {
        &:after {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3e%3ccircle cx='7.96039' cy='7.435' r='6' stroke='%232D7079' stroke-width='2'/%3e%3cpath d='M15.7533 16.1421C16.1438 16.5326 16.777 16.5326 17.1675 16.1421C17.558 15.7516 17.558 15.1184 17.1675 14.7279L15.7533 16.1421ZM11.7533 12.1421L15.7533 16.1421L17.1675 14.7279L13.1675 10.7279L11.7533 12.1421Z' fill='%232D7079'/%3e%3c/svg%3e");
        }
      }

      .nav-toggler {
        display: none;
      }
      &:has(.nav-search > input:focus) .nav-toggler {
        display: flex;
        width: 16px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='19' height='17' viewBox='0 0 19 17' fill='none'%3e%3cpath d='M1.96039 1.93506H17.9604M1.96039 8.93506H17.9604M1.96039 15.9351H17.9604' stroke='%2357A6B1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;
        height: 100%;
        cursor: pointer;
      }
      &:has(.nav-search > input:focus) .nav-item.link-item {
          display: none;
      }
    }
  }
  .logo {
    margin-left: 130px;
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background: url("../images/main/logo.png") center center no-repeat $white;
    background-size: 74px;
  }
  .redirect-link {
    border-radius: 19px 0px 0px 19px;
    background: $white;
    padding: 8px 21px 10px 24px;
    color: #000;

    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    a {
      color: #57A6B1;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}

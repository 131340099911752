.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 10 7' fill='none'%3e%3cpath d='M4 3.91421L1.70711 6.20711C1.31658 6.59763 0.683417 6.59763 0.292893 6.20711C-0.0976317 5.81658 -0.0976317 5.18342 0.292893 4.79289L4.29289 0.792893C4.68342 0.402369 5.31658 0.402369 5.70711 0.792893L9.70711 4.79289C10.0976 5.18342 10.0976 5.81658 9.70711 6.20711C9.31658 6.59763 8.68342 6.59763 8.29289 6.20711L6 3.91421L5 3L4 3.91421Z' fill='%238C8C8C'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #FFFFFF;
  background-image: var(--bs-form-select-bg-img);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none
  }
}

.form-select:focus {
  border-color: #D9D9D9;
  outline: 0;
  box-shadow: none;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: .75rem;
  background-image: none
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg)
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: none;
}


section.intro.feedback {
  padding-top: 90px;
  padding-bottom: 95px;
  margin-bottom: 60px;
  .container {
    border-radius: 20px;
    background: #FFF;
    padding: 60px 30px;
  }
}

//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "btns";
@import "forms";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
body.index {
  background: url("../images/main/bg_main_1.png") top left no-repeat,
  url("../images/main/bg_main_2.png") bottom left no-repeat,
  url("../images/main/bg_main_3.png") bottom right no-repeat,
  linear-gradient(293deg, #8ADAE5 11.74%, #3E8C97 78.47%);
  height: 100%;
}
body {
  background: #FAF0EA;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

section.content {
  background: url("../images/main/bg_main_2.png") bottom left no-repeat,
              url("../images/main/bg_main_3.png") bottom right no-repeat,
              transparent;
  flex: 1;
}
.hidden {
  display: none;
}
* {
  transition: all 1s ease 0s;
}
@import "header";
@import "footer";
@import "index";

@import "video";
@import "photo";

@import "section_navigation";
@import "section_feedback";
@import "section_intro";
@import "section_intro_feedback";
@import "section_contact";

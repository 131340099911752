.video-container {
  position: relative;
  cursor: pointer;
  .video {
    position: relative;
    z-index: 0;
  }
  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -35px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='66' height='67' viewBox='0 0 66 67' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.73132 14.1343C2.73132 11.1174 5.17705 8.67163 8.19401 8.67163H57.3582C60.3752 8.67163 62.8209 11.1174 62.8209 14.1343V52.3731C62.8209 55.3901 60.3752 57.8358 57.3582 57.8358H8.19401C5.17708 57.8358 2.73132 55.3902 2.73132 52.3731V14.1343ZM27.355 24.0364C28.2235 23.5551 29.2847 23.5829 30.1267 24.1092L41.0521 30.9375C41.8507 31.4367 42.3358 32.312 42.3358 33.2537C42.3358 34.1955 41.8507 35.0708 41.0521 35.5699L30.1267 42.3983C29.2847 42.9245 28.2235 42.9524 27.355 42.471C26.4866 41.9897 25.9477 41.075 25.9477 40.0821V26.4254C25.9477 25.4325 26.4866 24.5177 27.355 24.0364Z' fill='white'/%3e%3c/svg%3e");
    z-index: 100;
    display: block;
    width: 70px;
    height: 70px;
  }
  &:hover {
    .play {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='66' height='66' viewBox='0 0 66 66' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.73132 13.6866C2.73132 10.6696 5.17705 8.22388 8.19401 8.22388H57.3582C60.3752 8.22388 62.8209 10.6696 62.8209 13.6866V51.9254C62.8209 54.9424 60.3752 57.3881 57.3582 57.3881H8.19401C5.17708 57.3881 2.73132 54.9424 2.73132 51.9254V13.6866ZM27.355 23.5887C28.2235 23.1073 29.2847 23.1352 30.1267 23.6614L41.0521 30.4898C41.8507 30.9889 42.3358 31.8642 42.3358 32.806C42.3358 33.7477 41.8507 34.623 41.0521 35.1221L30.1267 41.9505C29.2847 42.4767 28.2235 42.5046 27.355 42.0233C26.4866 41.5419 25.9477 40.6272 25.9477 39.6343V25.9776C25.9477 24.9847 26.4866 24.07 27.355 23.5887Z' fill='%23EE7C00'/%3e%3c/svg%3e");
    }
  }
  &.video-is-playing {
    .play {
      display: none;
    }
  }
}
.video-gallery {
  margin-bottom: 60px;
  .controls {
    margin-bottom: 20px;

    .search {
      position: relative;
      float: right;
      input {
        border-radius: 20px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        color: #8C8C8C;

        font-family: "Rubik";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px 35px 10px 16px;
        outline: none;
        &::placeholder {
          color: #8C8C8C;
        }
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
        &:hover {
          border: 1px solid #BFBEBE;
          &::placeholder {
            color: #717171;
          }
        }
        &:focus {
          width: 600px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        right: 20px;
        top:0;
        display: block;
        width: 15px;
        height: 37px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3e%3ccircle cx='7.96039' cy='7.435' r='6' stroke='black' stroke-width='2'/%3e%3cpath d='M15.7533 16.1421C16.1438 16.5326 16.777 16.5326 17.1675 16.1421C17.558 15.7516 17.558 15.1184 17.1675 14.7279L15.7533 16.1421ZM11.7533 12.1421L15.7533 16.1421L17.1675 14.7279L13.1675 10.7279L11.7533 12.1421Z' fill='black'/%3e%3c/svg%3e");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
      }
    }
    .search:has(> input:focus) {
      &:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'%3e%3ccircle cx='7.96039' cy='7.435' r='6' stroke='%232D7079' stroke-width='2'/%3e%3cpath d='M15.7533 16.1421C16.1438 16.5326 16.777 16.5326 17.1675 16.1421C17.558 15.7516 17.558 15.1184 17.1675 14.7279L15.7533 16.1421ZM11.7533 12.1421L15.7533 16.1421L17.1675 14.7279L13.1675 10.7279L11.7533 12.1421Z' fill='%232D7079'/%3e%3c/svg%3e");
      }
    }
  }
  .showmore {
    display: block;
    margin: 25px auto 0;
  }
  .video-list {
    & > * {
      gap: 20px;
    }
    .video-item {
      margin-bottom: 30px;
    }
    .video {
      border-radius: 9px;
    }
    .video-title {
      color: #252525;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }
    .video-info {
      color: #777;
      font-family: "Rubik";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.btn {
  --bs-btn-padding-x: 16px;
  --bs-btn-padding-y: 16px;
  --bs-btn-font-family: "inter";
  --bs-btn-font-size: 18px;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 18px;
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 20px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: none;
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #EE7C00;
  --bs-btn-border-color: #EE7C00;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #FF9B2E;
  --bs-btn-hover-border-color: #FF9B2E;
  --bs-btn-focus-shadow-rgb: 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #CE6B00;
  --bs-btn-active-border-color: #CE6B00;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: trnsparent;
  --bs-btn-disabled-border-color: #CE6B00;
}

.btn-secondary {
  --bs-btn-color: #EE7C00;
  --bs-btn-bg: transparen;
  --bs-btn-border-color: #EE7C00;
  --bs-btn-border-width: 2px;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #EE7C00;
  --bs-btn-hover-border-color: #EE7C00;
  --bs-btn-focus-shadow-rgb: 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #CE6B00;
  --bs-btn-active-border-color: #CE6B00;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: trnsparent;
  --bs-btn-disabled-border-color: #CE6B00;
}

section.feedback {
  margin-bottom: 60px;
  .container {
    border-radius: 20px;
    background: #FFF;
    padding: 60px 30px;
  }
  .mb-3 {
    margin-bottom: 20px!important;
  }
  h2 {
    color: #57A6B1;
    font-family: "HeliosExtraCompressedC";
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: uppercase;
    margin: 0 0 10px;
    padding: 0;
  }
  .intro {
    color: #381365;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 15px;
  }
  .subtitle {
    color: #381365;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .description {
    color: #928A9C;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
  .form-control {
    border-radius: 20px;
    border: 1px solid #D6ECF1;
    background: #FFF;
    padding: 12px 18px;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    &::placeholder {
      color: #928A9C;

      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
  }
  textarea.form-control {
    padding: 18px 18px;
  }
}

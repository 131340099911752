section.title {
  h1 {
    color: #FFF;

    text-align: center;
    font-family: "Heliosextracompressedc";
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 93.4%; /* 89.664px */
    text-transform: uppercase;
  }
}

section.intro {
  .content-container {
    border-radius: 20px;
    background: $white;
    .title {
      color: #57A6B1;

      text-align: center;
      font-family: "Inter";
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 20px;
    }
    .content {
      border-radius: 0 0 20px 20px;
      background: #FAF0EA;
      .description {
        color: #000;

        text-align: center;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding: 20px 20px 30px;
      }
      .intro-status-choose {
        text-align: center;
        padding-bottom: 45px;

        button {
          text-indent: 0;
          border-radius: 20px;
          background: #EE7C00;
          color: #FFF;

          text-align: center;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px;
          width: 280px;
          border: 0;
          display: block;
          margin: 0 auto;
          &:hover {
            background: #FF9B2E;
          }
        }
        .field-group {
          display: flex;
          justify-content: center;
          margin-bottom: 25px;
        }
        .field input[type=radio] {
          display: none;
        }
        .field {
          label {
            display: inline-block;
            cursor: pointer;
            user-select: none;
            width: 90px;
            margin: 0 25px;
            color: #000;

            text-align: center;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            position: relative;
            z-index: 100;
            &:before {
              content: '';
              display: block;
              width: 80px;
              height: 80px;
              background-position: center center;
              background-repeat: no-repeat;
              background-color: transparent;
              z-index: 90;
              border-radius: 19px;
              margin: 5px 5px 15px 5px;
              box-sizing: content-box;
            }
            &:after {
              z-index: 80;
              content: '';
              display: block;
              width: 90px;
              height: 90px;
              border-radius: 19px;
              border-style: solid;
              border-width: 1px;
              border-color: transparent;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &.student label:before {
            background-image: url("../images/main/student.png");
          }
          &.parent label:before {
            background-image: url("../images/main/parent.png");
          }
          &.ex-student label:before {
            background-image: url("../images/main/ex-student.png");
          }
          &.teacher label:before {
            background-image: url("../images/main/teacher.png");
          }
          &.guest label:before {
            background-image: url("../images/main/guest.png");
          }

          /* Checked */
          input[type=radio]:checked + label {
            font-weight: 700;
          }
          &.student input[type=radio]:checked + label:before {
            background-color: #D6ECF1;
          }
          &.parent input[type=radio]:checked + label:before {
            background-color: #D7BBF9;
          }
          &.ex-student input[type=radio]:checked + label:before {
            background-color: #FEDA79;
          }
          &.teacher input[type=radio]:checked + label:before {
            background-color: #86CBD3;
          }
          &.guest input[type=radio]:checked + label:before {
            background-color: #D6ECF1;
          }

          &.student input[type=radio]:checked + label:after {
            border-color: #3DB0CA;
          }
          &.parent input[type=radio]:checked + label:after {
            border-color: #9747FF;
          }
          &.ex-student input[type=radio]:checked + label:after {
            border-color: #ECAC00;
          }
          &.teacher input[type=radio]:checked + label:after {
            border-color: #3C878F;
          }
          &.guest input[type=radio]:checked + label:after {
            border-color: #0E96B5;
          }

          /* hover */
          label:hover {
            font-weight: 700;
          }
          &.student label:hover:after {
            border-color: #3DB0CA;
          }
          &.parent label:hover:after {
            border-color: #9747FF;
          }
          &.ex-student label:hover:after {
            border-color: #ECAC00;
          }
          &.teacher label:hover:after {
            border-color: #3C878F;
          }
          &.guest label:hover:after {
            border-color: #0E96B5;
          }
        }

      }
    }
  }
}

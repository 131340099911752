.bg-light-blue {
  background-color: $light-blue!important;
}
.bg-light-orange {
  background-color: $light-orange!important;
}
section.intro {
  padding-top: 20px;
  padding-bottom: 60px;
  margin-bottom: 60px;
  .container {
    border-radius: 20px;
    background: #FFF;
    padding: 30px;
  }
  .info {
    h1 {
      color: #3E8C97;
      font-family: "HeliosUltraCompressedC";
      font-size: 60px;
      font-style: normal;
      font-weight: 500;
      line-height: 60px;
      text-transform: uppercase;
      padding: 0;
      margin: 0 0 40px;
    }
    .description {
      color: #515151;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      margin: 0 0 30px;
    }
  }
  &.bg-light-blue {
    h1 {
      color: #3E8C97;
    }
  }
  &.bg-light-orange {
    h1 {
      color: #EE7C00;
    }
  }
  video {
    border-radius: 9px;
    width: 100%;
    height: 450px;
  }
}

footer {
  padding: 25px 20px 30px;
  background: #484747;
  .logo {
    margin-left: 110px;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: url("../images/main/logo.png") center center no-repeat $white;
    background-size: 84px;
    float: left;
  }
  .copy {
    padding-left: 23px;
    color: #979797;

    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      flex: 1
    }
  }
  .info {
    color: #979797;

    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

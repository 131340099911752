section.section-navigation {
  margin-bottom: 60px;
  .container {
    padding: 0;
  }
  .row > * {
    gap: 20px;
  }
  .block-title {
    border-radius: 20px;
    background: linear-gradient(180deg, #57A6B1 4.85%, #3E8C97 93.53%);
    position: relative;
    margin-top: 20px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -21px;
      bottom: 0;
      left: 0;
      background: url("../images/main/block-title.png") right -113px no-repeat transparent;
    }
    h1 {
      color: #FFF;
      font-family: "HeliosExtraCompressedC";
      font-size: 96px;
      font-style: normal;
      font-weight: 400;
      line-height: 93.4%;
      text-transform: uppercase;
      padding: 122px 40px 40px;
      margin: 0;
    }
  }
  .nav-block {
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    transition:  2s ease 0s;
    &.height-1 {
      height: 100px;
    }
    &.height-2 {
      height: 220px;
    }
    .title {
      font-family: "HeliosUltraCompressedC";
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      transition: all 1s ease-in-out;
    }

    .description {
      display: none;
      font-family: "Inter";
      font-style: normal;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
      transition: all 1s ease 0s;
    }
    &:hover {
      .description {
        display: block;
        transition: all 1s ease 0s;
        animation: fadeInFromNone 1s ease-in-out;
      }
    }
    @keyframes fadeInFromNone {
      0% {
        display: none;
        opacity: 0;
      }

      1% {
        display: block;
        opacity: 0;
      }

      100% {
        display: block;
        opacity: 1;
      }
    }
  }
  .block-tpl-blue {
    background: #57A6B1;
    .title {
      color: #FFF;
    }
    .description {
      color: #FFF;
    }
    &:hover {
      background: #3A95A1;
    }
  }
  .block-tpl-blue-dark {
    background: linear-gradient(180deg, #3E8C97 0%, #1C565E 100%);
    .title {
      color: #FFF;
    }
    .description {
      color: #FFF;
    }
    &:hover {
      background: linear-gradient(180deg, #0096AB 0%, #014953 100%);
    }
  }
  .block-tpl-blue-light {
    border: 3px solid #3E8C97;
    background: linear-gradient(0deg, #D6ECF1 0%, #FFF 91.5%);
    justify-content: center;
    .title {
      color: #3E8C97;
    }
    .description {
      color: #515151;
    }
    &:hover {
      border: 3px solid #3E8C97;
      background: linear-gradient(0deg, #D6ECF1 0%, #FFF 91.5%);
    }
  }
  .block-tpl-orange {
    background: #EEB659;
    .title {
      color: #fff;
    }
    .description {
      color: #fff;
    }
    &:hover {
      background: linear-gradient(180deg, #EEB659 0%, #F19600 100%);
    }
  }
  .block-tpl-orange-light {
    border: 3px solid #EE7C00;
    background: #FFD6AA;
    .title {
      color: #EE7C00;
    }
    .description {
      color: #515151;
    }
    &:hover {
      border: 3px solid #EE7C00;
      background: linear-gradient(0deg, #FFD6AA 37.02%, #FFECD7 80.65%);
    }
  }
  .block-tpl-orange-dark {
    background: linear-gradient(180deg, #EDA736 0%, #EE6D1A 100%);
    .title {
      color: #FFF;
    }
    .description {
      color: #FFF;
    }
    &:hover {
      background: linear-gradient(180deg, #FFAD28 0%, #A96D0D 100%);
    }
  }
  .block-believe {
    border: 3px solid #3E8C97;
    background: #A8D9E3 url("../images/main/block-believe.png") left top no-repeat;
    padding: 15px;
    justify-content: flex-end;
    .title {
      color: #218997;
      text-align: right;
      font-size: 60px;
      line-height: 82%; /* 49.2px */
    }
    .description {
      padding-top: 10px;
      color: #515151;
      text-align: right;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
    }
    &:hover {
      background-position: -52px -105px;
    }
  }
}

section.photo-gallery {
  margin-bottom: 60px;
  .container {
    padding: 0;
  }
  .image-gallery {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .image-gallery .column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .image-item img {
    width: 100%;
    border-radius: 9px;
    height: 100%;
    object-fit: cover;
  }

  .showmore {
    display: block;
    margin: 40px auto 60px;
  }
  @media only screen and (min-width: 768px) {
    .image-gallery {
      flex-direction: row;
    }
  }
  .accordion {

  }

  .accordion-button {
    color: #FFF;
    font-family: "HeliosUltraCompressedC";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 20px;

    border: 0;
    background: #57A6B1;
    padding: 20px 15px;

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    overflow-anchor: none;
    &:not(.collapsed) {
      color: #fff;
      background-color: #57A6B1;
      box-shadow: none;

      border-radius: 20px 20px 0 0;

      &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10' fill='none'%3e%3cpath d='M10.2 4.30964L14.0979 0.488155C14.7618 -0.16272 15.8382 -0.16272 16.5021 0.488155C17.166 1.13903 17.166 2.1943 16.5021 2.84518L9.70208 9.51184C9.03819 10.1627 7.96181 10.1627 7.29792 9.51184L0.497918 2.84518C-0.165973 2.1943 -0.165973 1.13903 0.497918 0.488154C1.16181 -0.16272 2.23819 -0.16272 2.90208 0.488154L6.8 4.30964L8.5 5.83333L10.2 4.30964Z' fill='white'/%3e%3c/svg%3e");        transform: var(--#{$prefix}accordion-btn-icon-transform);
      }
    }

    // Accordion icon
    &::before {
      flex-shrink: 0;
      width: 17px;
      height: 10px;
      margin-right: 17px;
      content: "";
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10' fill='none'%3e%3cpath d='M6.8 5.69036L2.90208 9.51184C2.23819 10.1627 1.16181 10.1627 0.497919 9.51184C-0.165974 8.86097 -0.165974 7.8057 0.497919 7.15482L7.29792 0.488155C7.96181 -0.162719 9.03819 -0.162719 9.70208 0.488155L16.5021 7.15482C17.166 7.8057 17.166 8.86097 16.5021 9.51185C15.8382 10.1627 14.7618 10.1627 14.0979 9.51185L10.2 5.69036L8.5 4.16667L6.8 5.69036Z' fill='white'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
    }

    &:hover {
      z-index: 2;
      border-color: #4ACCDE;
      background-color: #4ACCDE;
    }
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-body {
    padding: 20px;
    background: #fff;
    border-radius: 0 0 20px 20px;
  }

}

section.contact {
  margin-bottom: 60px;
  .container {
    padding: 0 30px;
  }
  h1 {
    color: #57A6B1;
    font-family: "HeliosExtraCompressedC";
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 93.4%;
    text-transform: uppercase;
    margin: 0 0 30px;
    padding: 0;
  }
  .address {
    color: #381365;
    font-family: "Inter";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding-left: 60px;
    a {
      color: #381365;
      text-decoration: none;
    }
    .place {
      position: relative;
      line-height: 43px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -60px;
        width: 43px;
        height: 43px;
        background: url("../images/main/place.png") center center no-repeat transparent;
      }
    }
    .phone {
      position: relative;
      line-height: 43px;
      display: block;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -60px;
        width: 43px;
        height: 43px;
        background: url("../images/main/phone.png") center center no-repeat transparent;
      }
    }
  }
}
